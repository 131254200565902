<template>
  <div class="Part">
    <div class="input">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
      <el-row style="padding: 5px 5px 5px 5px;">
        &nbsp;&nbsp;优惠名称 &nbsp;&nbsp;
        <el-input v-model="scFrom.name" @input="scMethod" clearable size="mini" style="width:125px"></el-input>&nbsp;&nbsp;套餐状态 &nbsp;&nbsp;
        <el-select v-model="scFrom.status" size="mini" style="width:125px" @change="scMethod">
          <el-option label="正常" :value="Number(0)"></el-option>
          <el-option label="注销" :value="Number(1)"></el-option>
        </el-select>
      </el-row>
    </div>
    <div style="text-align:center;">
      <el-table :data="tableData" border :height="tableHeight" highlight-current-row>
        <el-table-column prop="sfpName" label="优惠政策名称" align="center"></el-table-column>
        <el-table-column prop="sfpFavorablePrice" label="优惠价格" align="center"></el-table-column>
        <el-table-column prop="sppPackName" label="适用套餐" align="center"></el-table-column>
        <el-table-column label="优惠政策状态" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sfpPolicyState | discountStatusFilter2}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="130px">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleChange(scope.$index, scope.row)">修改</el-button>
            <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange($event,'pageSize',getData,'clickrow')"
        @current-change="currentChange($event,'currentPage',getData,'clickrow')"
      ></el-pagination>
    </div>

    <el-dialog
      :title="addFrom.title"
      :visible.sync="addDia"
      width="350px"
      :close-on-click-modal="false"
      @close="addDiaClose"
      v-loading="loading"
    >
      <el-form ref="addFrom" :model="addFrom" :rules="addFromRule" label-width="80px" size="mini">
        <el-form-item label="名称" prop="sfpName">
          <el-input v-model="addFrom.sfpName"></el-input>
        </el-form-item>
        <el-form-item label="优惠价格" prop="sfpFavorablePrice">
          <el-input v-model="addFrom.sfpFavorablePrice"></el-input>
        </el-form-item>
        <el-form-item label="适用套餐">
          <el-select v-model="addFrom.sfpSppId" style="width:100%">
            <el-option label="全部" :value="(null)"></el-option>
            <el-option
              v-for="item in addFrom.sfpSppOpt"
              :key="item.sppId"
              :label="item.sppPackName"
              :value="item.sppId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="addSave">保 存</el-button>
        <el-button size="mini" @click="addDia = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "discount",
  data() {
    let checkMoney = (rule,value,callback)=> {
      let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
      if (!value) {
        return callback(new Error('金额不能为空'))
      }
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的金额'))
      }
    }
    return {
      addDia: false,
      loading: false,
      scFrom: {
        name: "",
        status: 0
      },

      addFromRef: ["addFrom"],
      addFrom: {
        title: "",
        sfpName: "",
        sfpFavorablePrice: "",
        sfpSppId: null,
        sfpSppOpt: []
      },
      addFromRule:{
        sfpName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        sfpFavorablePrice: [
          { validator: checkMoney , trigger: 'blur' },
        ]
      },

      tableData: [],
      tableHeight: window.innerHeight - 225,
      clickrow: "",

      // 首页分页
      total: 0,
      pageSize: 10,
      currentPage: 1
    };
  },
  methods: {
    scMethod() {
      this.currentPage = 1;
      this.getData();
    },
    async add() {
      this.addFrom.title = "添加";
      this.addFrom.sfpSppOpt = await this.getPlan();
      this.addDia = true;
    },
    addSave() {
      if (this.submitForm(this.addFromRef, this)){
        this.loading = true
        let flag = this.addFrom.title == "添加" ? true : false;
        this.$axios({
          url: flag ? "/common2/favouredPolicys": `/common2/favouredPolicys/${this.clickrow.sfpId}`,
          method: flag ? "post" : 'put',
          data: {
            sfpName: this.addFrom.sfpName,
            sfpFavorablePrice: Number(this.addFrom.sfpFavorablePrice),
            sfpSppId: this.addFrom.sfpSppId
          }
        }).then(res => {
          this.loading = false
          this.$message.success("成功");
          this.getData();
          this.addDia = false;
        }).catch(err => {
          this.addDia = false;
          this.isError(err,this)
        })
      }
    },
    addDiaClose(){
      this.addFrom= {
        title: "",
        sfpName: "",
        sfpFavorablePrice: "",
        sfpSppId: null,
        sfpSppOpt: []
      }
      this.resetForm(this.addFromRef, this)
    },

    async handleChange(index, row) {
      this.addFrom.title = "修改";
      this.addFrom.sfpSppOpt = await this.getPlan();
      this.clickrow = await this.getSingle(row.sfpId);
      console.log(this.clickrow);

      this.addFrom.sfpName = this.clickrow.sfpName;
      this.addFrom.sfpFavorablePrice = this.clickrow.sfpFavorablePrice;
      
      this.addFrom.sfpSppId = this.clickrow.sfpSppId == undefined?null:this.clickrow.sfpSppId;
      this.addDia = true;
    },
    //查询全部
    getData() {
      this.$axios({
        url: "/common2/favouredPolicys",
        method: "get",
        params: {
          cursor: this.currentPage,
          limit: this.pageSize,
          sfpPolicyState: this.scFrom.status,
          sfpName: this.scFrom.name
        }
      }).then(res => {
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查单条
    getSingle(id) {
      return this.$axios({
        url: `/common2/favouredPolicys/${id}`,
        method: "get"
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查套餐
    getPlan() {
      return this.$axios({
        url: "/common2/packagePlans",
        method: "get",
        params: {
          cursor: 1,
          limit: 150,
          sppProgrammeState: "0",
          sppPackName: ""
        }
      }).then(res => {
        return res.data.result.records;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //选择数据条数
    sizeChange(callVal, pageSize, getData, ...cleanRow) {
      this.$data[pageSize] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    //具体那一页
    currentChange(callVal, currentPage, getData, ...cleanRow) {
      this.$data[currentPage] = callVal;
      this.$data[cleanRow] = "";
      getData();
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style>
</style>